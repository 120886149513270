import React from 'react';

import GlobalStyles from '@styles/GlobalStyles';
import Navigation from '@core/Navigation';
import Seo from '@core/Seo';

const Layout = ({ children, location: { pathname } }) => (
  <>
    <GlobalStyles />
    <Seo />
    <Navigation isHomepage={pathname === '/'} />
    {children}
  </>
);

export default Layout;
