/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import '@styles/fonts/fonts.css';
// import { isSmall } from '@utils';

// function intersectionObserverSupported() {
//   if (
//     'IntersectionObserver' in window &&
//     'IntersectionObserverEntry' in window &&
//     'intersectionRatio' in window.IntersectionObserverEntry.prototype
//   ) {
//     // Minimal polyfill for Edge 15's lack of `isIntersecting`
//     // See: https://github.com/w3c/IntersectionObserver/issues/211
//     if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
//       Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
//         get() {
//           return this.intersectionRatio > 0;
//         },
//       });
//     }
//     return true;
//   }

//   return false;
// }

// function smoothScrollSupported() {
//   return 'scrollBehavior' in document.documentElement.style;
// }

// function loadScript(src) {
//   const js = document.createElement('script');
//   js.src = src;
//   document.head.appendChild(js);
// }

// function setViewportHeight() {
//   const root = document.documentElement;
//   root.style.setProperty('--viewport-height', `${window.innerHeight}px`);
// }

export const onClientEntry = () => {
  const polyfills = [];

  // setViewportHeight();

  // if (!intersectionObserverSupported()) {
  //   polyfills.push('IntersectionObserver');
  // }

  // if (!smoothScrollSupported()) {
  //   polyfills.push('smoothscroll');
  // }

  // if (polyfills.length > 0) {
  //   loadScript(`https://polyfill.io/v3/polyfill.min.js?features=${polyfills.join('%2C')}`);
  // }
};

// page transitions
// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   const TRANSITION_DELAY = 500;

//   window.history.scrollRestoration = 'manual';

//   const currentPosition = getSavedScrollPosition(location);

//   window.setTimeout(() => {
//     window.scrollTo(...currentPosition);
//   }, TRANSITION_DELAY);

//   return false;
// };
