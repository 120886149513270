import React from 'react';
import styled, { css } from 'styled-components';
import withLink from '@common/withLink';
import { hover } from '@mq';

const Link = ({ title, children, ...rest }) => (
  <Container title={title} {...rest}>
    {children || title}
  </Container>
);

const Container = styled.a`
  ${props =>
    props.to &&
    css`
      ${hover} {
        /* transition: color var(--motion-triple) ease-out; */

        &:hover {
          font-style: italic;
          font-feature-settings: 'ss01';
        }
      }
    `}
`;

export default withLink(Link);
