import React, { useCallback } from 'react';
import styled from 'styled-components';

import Link from '@core/Link';
import { layoutWrapper } from '@mixins';
import isBrowser from '@utils/isBrowser';
import isSmall from '@utils/isSmall';

const Navigation = ({ isHomepage }) => {
  const handleHomeClick = useCallback(() => {
    if (isBrowser && window.fullpage_api && isHomepage) {
      if (isSmall) {
        window.fullpage_api.moveTo(1);
      } else {
        window.fullpage_api.moveTo(1, 0);
      }
    }
  }, [isHomepage]);

  return (
    <Container>
      <Link onClick={handleHomeClick} to="/#the-blueberry-machine">
        Monte Packham
      </Link>
      <Links>
        <li>
          <Link to="/archive">Archive</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </Links>
    </Container>
  );
};

const Container = styled.nav`
  ${layoutWrapper};

  display: flex;
  justify-content: space-between;
  padding-top: var(--navigation-padding-block);
  padding-bottom: var(--navigation-padding-block);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
`;

const Links = styled.ul`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;

  .link-active {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      left: 0;
      right: 0;
      border-bottom: 1px solid currentColor;
    }
  }
`;

export default Navigation;
