import React from 'react';
import Reset from './Reset';
import Base from './Base';
import Vars from './Vars';

const GlobalStyles = () => (
  <>
    <Reset />
    <Vars />
    <Base />
  </>
);

export default GlobalStyles;
