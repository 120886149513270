import mq, { hover, mqdown } from '@mq';
import { createGlobalStyle } from 'styled-components';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    line-height: 1.25;
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    font: var(--font);
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;
  }

  /* * {
    font-feature-settings: "ss01";

    ${hover} {
      &:hover {
        font-feature-settings: "ss01";
      }
    }
  } */

  main {
  }

  h1 {
    font: var(--font);
  }

  h2 {
    font: var(--font);
  }

  h3 {
    font: var(--font);
  }

  .fp-watermark {
    display: none;
  }

  #fp-nav {
    left: 0 !important;
    z-index: 0;
    transition: opacity var(--motion-triple), visibility var(--motion-triple);
    opacity: 1;
    visibility: visible;

    ${mqdown.medium} {
      display: none;
    }
    
    .fullpage-navigation-hidden & {
      opacity: 0;
      visiblity: hidden;
      pointer-events: none;
    }

    ul {
      li {
        height: 1.5rem !important;
        margin: 0 !important;
        padding-left: var(--gutter) !important;

        &:hover {
          cursor: pointer !important;
          
          a {
            span {
              height: 0.5rem !important;
              width: 0.5rem !important;
              border-radius: 50% !important;
              margin: 0 !important;
              transform: none !important;
              left: initial !important;
              top: initial !important;
              background-color: var(--color-text) !important;
            }

            &.active {
              span {
                height: 0.5rem !important;
                width: 0.5rem !important;
                border-radius: 50% !important;
                margin: 0 !important;
                transform: none !important;
                left: initial !important;
                top: initial !important;
                background-color: var(--color-text) !important;
              }
            }

            & + .fp-tooltip {
              font: var(--font) !important;
              max-width: initial !important;
              color: var(--color-text) !important;
            }
          }
        }

        a {
          padding: 0.5rem 12rem 0.5rem var(--gutter) !important;
          margin-left: calc(-1 * var(--gutter)) !important;
          height: 1.5rem !important;
          

          span {
            height: 0.5rem !important;
            width: 0.5rem !important;
            border-radius: 50% !important;
            margin: 0 !important;
            transform: none !important;
            left: initial !important;
            top: initial !important;
            background-color: transparent !important;
            border: 0.0625rem solid var(--color-text) !important;
          }

          & + .fp-tooltip {
            font: var(--font) !important;
            max-width: initial !important;
            color: rgba(0,0,0,0.3) !important;
            margin-left: 1.25rem !important;
            top: 0 !important;
            bottom: 0 !important;
            display: flex !important;
            flex-direction: row !important;
            align-items: center !important;
          }

          &.active {
            span {
              height: 0.5rem !important;
              width: 0.5rem !important;
              border-radius: 50% !important;
              margin: 0 !important;
              transform: none !important;
              left: initial !important;
              top: initial !important;
              background-color: var(--color-text) !important;
            }

            & + .fp-tooltip {
              font: var(--font) !important;
              max-width: initial !important;
              color: var(--color-text) !important;
            }
          }
        }
      }
    }
  }

  .fp-arrow {
    position: absolute !important;
    height: 100vh !important;
    width: 50vw !important;
    top: 0 !important;
    bottom: 0 !important;
    z-index: 3 !important;
    margin: 0 !important;
    transform: none !important;
    cursor: none !important;
    user-select: none !important;

    /* &:hover {
      user-select: none !important;
      cursor: none !important;
    }

    &:focus {
      user-select: none !important;
      cursor: none !important;
    }

    &:active {
      user-select: none !important;
      cursor: none !important;
    } */

    &.fp-prev,
    &.fp-next {
      border-width: 0 !important;
      border-color: initial !important;
      border: none !important;
    }

    &.fp-prev {
      left: 0 !important;
    }

    &.fp-next {
      right: 0 !important;
    }
  }
`;

export default Base;
