import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-grey: #cdcdcd;
    --color-text: var(--color-black);
    --color-background: var(--color-white);

    --gutter: 0.625rem; //10
    --grid-gap: 0.625rem; //10
    --grid-column-count: 6;

    ${mq.small} {
      --gutter: 1.25rem; //20
      --grid-gap: 0.9375rem; //15
    }
    
    ${mq.medium} {
      --grid-column-count: 12;
    }

    --navigation-height: 2.3125rem; //37
    --navigation-padding-block: 0.5rem; //8
    
    ${mq.small} {
      --navigation-height: 2.8125rem; //45
      --navigation-padding-block: 0.75rem; //12
    }

    --font-family: 'Diatype', sans-serif;
    --font: 1rem/ 1.3125 var(--font-family); //16
    
    --motion-half: 50ms;
    --motion: 100ms;
    --motion-double: 200ms;
    --motion-triple: 300ms;
    --motion-quadruple: 400ms;
    --motion-quintuple: 500ms;
  }
`;

export default Vars;
